import React, { useEffect, useState } from 'react';
import { getFeildTeamById, isTokenValid } from '../../APIs/apiCalls';
import Layout from '../../Layout';

const AdminProfile = () => {
    const [data, setData] = useState(undefined);
    
    useEffect(() => {
        const token = localStorage.getItem('token');
       
        isTokenValid({token}).then((response) => {
            const id = response.data._id;
            // console.log(response);
            getFeildTeamById(id).then((response_) => {
                // console.log(response_);
                setData(response_.data);
            })
        })
    }, [])
    if(typeof data === 'undefined'){
        return <>Loading..</>
    }
  return (
       
    <div className="col-12 grid-margin">
        <div className="card">
            <div className="card-body">
                <h5><u>Admin Profile</u></h5> <br/>
                {
                    data && data.firstname ? <p>Name : { data.firstname.toUpperCase() + ' ' + data.lastname.toUpperCase() } </p> : null
                }
                <p>Email : { data.email }</p>
                <p>Role : { data.type.toUpperCase() }</p>
                <p>
                    Project Assigned : 
                    {
                        data.projects.map((project) => <span className='text-capitalize'> {project}</span>)
                    }
                </p>
                <p>Locations : </p>
                <ol>
                    {
                        data.locations.map((item) => <li>
                            { item.state && <span className='text-capitalize'>{item.state} </span> }
                            { item.district && <span className='text-capitalize'>| {item.district} </span> }
                            { item.tehsil && <span className='text-capitalize'>| {item.tehsil} </span> }
                            { item.village && <span className='text-capitalize'>| {item.village} </span> }
                            { item.block && <span className='text-capitalize'>| {item.block} </span> }
                        </li>)
                    }
               </ol>

            </div>
        </div>
    </div>
  )
}

export default AdminProfile

{/*  */}