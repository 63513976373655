import { useEffect, useState } from "react";
import { deleteFeildData, fetchOptions, getFeildData, getFieldStatus, getTableData, getfarmers, uploadCropsByCSV } from "../../../../APIs/apiCalls";
import MaterialReactTable from "material-react-table";
import { useNavigate } from "react-router-dom";
import { mkConfig, generateCsv, download } from "export-to-csv";
import AlertConfirm from "react-alert-confirm";
import { NotificationContainer, NotificationManager } from "react-notifications";
import Spinners1 from "../../../../Components/Spinners/Spinners1";
import CSVErrorMessages from "../../../../Components/CSVErrorMessages/CSVErrorMessages";
import { isFileCSV } from "../../../../Utils/commonFunctions";
import moment from "moment";
import { fetchUrl } from "../../../../APIs/server";
const url_ = fetchUrl;

const csvConfig = mkConfig({ useKeysAsHeaders: true });

const CropDetails = ({ crop }) => 
    <div className="row" style={{fontStyle : 'italic'}}>
        <div className="col-md-6">
          <p>Year : {crop.year}</p>  
          <p>Crop : {crop.crop.toUpperCase()}</p>
          <p>Area : {crop.area} Acres</p>
          <p>Variety : {crop.variety}</p>
          <p>Sowing Date : {crop.sowingDate ? moment(crop.sowingDate).format('DD-MM-YYYY') : <span className="text-danger">Not Entered</span>}</p>
          <p>Expected Sowing Date : {crop.expectedSowingDate ? moment(crop.expectedSowingDate).format('DD-MM-YYYY') : <span className="text-danger">Not Entered</span>}</p>
        </div>
        <div className="col-md-6">
          <p>Date of Nursery : {crop.dateOfNursery ? moment(crop.dateOfNursery).format('DD-MM-YYYY') : <span className="text-danger">Not Entered</span>}</p>  
          <p>Harvesting Date : {crop.harvestingDate ? moment(crop.harvestingDate).format('DD-MM-YYYY') : <span className="text-danger">Not Entered</span>}</p>
          <p>Expected Harvesting Date : {crop.expectedHarvestingDate ? moment(crop.expectedHarvestingDate).format('DD-MM-YYYY') : <span className="text-danger">Not Entered</span>}</p>
          <p>Land Prepration Date : {crop.landPreprationDate ? moment(crop.landPreprationDate).format('DD-MM-YYYY') : <span className="text-danger">Not Entered</span>}</p>
          <p>Yield : {crop.yield ? crop.yield : <span className="text-danger">Not Entered</span>}</p>
          <p>Major Buyers : {crop.majorBuyers ? crop.majorBuyers : <span className="text-danger">Not Entered</span>}</p>
        </div>
    </div>

const FeildInformation = () => {
    const userType = localStorage.getItem('type');
    const navigate = useNavigate();
    const [data, setData] = useState(undefined);
    const [flag, setFlag] = useState(false);
    const [pending, setPending] = useState([]);
    const [success, setSuccess] = useState([]); 
    const [disabled, setDisabled] = useState(false);
    const [fileUploadingErrors, setFileUploadingErrors] = useState([]);
    const [disabledIds, setDisabledIds] = useState([]);
    const [cropList, setCropList] = useState([]);
    const [ crop, setCrop] = useState('');
    const [year, setYear] = useState('');

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    //table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);

    // Dropdown state
    const [uniqueYears, setUniqueYears] = useState([]);
    const [uniqueCrops, setUniqueCrops] = useState([]);

    const columns = [
        {
            accessorKey: 'farmername',
            header: 'Farmer Name',
            Cell: (data) => {
                const { _id, farmername } = data.row.original;
                return (
                    <div style={{ color: "blue", textDecoration: 'underline', cursor: 'pointer', textTransform: 'uppercase' }}>
                        <a href={`/#/farmer-profile/${_id}`} target="_blank">{farmername}</a>
                    </div>
                )
            }
        },
        {
            accessorKey: 'guardian',
            header: 'Guardian Name',
            Cell: ({ cell }) => {
                const val = cell.getValue();
                return (
                    <div style={{ textTransform: 'uppercase' }}>{val}</div>
                )
            }
        },
        {
            accessorKey : 'village',
            header : 'Village',
            Cell : (data) => {
                const {village} =  data.row.original;
                return <>{village!=undefined ? village.toUpperCase() : <span className="text-danger">Not Entered </span> }</>
            }
        },
        {
            accessorKey: 'crop',
            header: 'Status',
            filterVariant: 'select',
            filterSelectOptions: ['success', 'pending'],
            filterFn: (row, id, filterValue) => {
                const value = row.original.crop;
                console.log(value);
                if (filterValue === 'success') {
                    return value !== undefined && value.length > 0;
                }
                if (filterValue === 'pending') {
                    return value === undefined || value.length === 0;
                }
                return true;
            },
            Cell: ({ cell }) => {
                const val = cell.getValue();
                return <>{val === undefined || val.length === 0 ? <span className="badge bg-danger text-white">Pending <i className="typcn typcn-warning"></i></span> : <span className="badge bg-success text-white">Success<i className="typcn typcn-input-checked"></i></span>}</>
            }
        },
        {
            accessorKey: 'projectName',
            header: 'Project Name',
            Cell : ({cell}) => {
                return <>{cell.getValue() === undefined ? 'Not Entered' : cell.getValue().toUpperCase()}</>
            }
        },
        {
            accessorKey: 'cropName',
            header: 'Crop',
            Filter: () => (
                <div>
                  <select
                    className="form-control mt-2"
                    id="crop"
                    onChange={(event) => setCrop(event.target.value)}
                  >
                    <option value=''>Select Crop</option>
                    {
                        cropList.map((crop) => <option value={crop}>{crop.toUpperCase()}</option>)
                    }
                  </select>
                  <select
                    className="form-control mt-2" id="year"
                    onChange={(event) => setYear(event.target.value)}
                  >
                    <option value=''>Select Year</option>
                    <option value={2023}>2023</option>
                    <option value={2024}>2024</option>
                  </select>
                </div>
              ),
            Cell: ({ row }) => {
                const crops = row.original.crop;
                return <div>
                    {
                        crops.length>0 ? 
                        crops.map((crop) => 
                            <div>
                                <p>Crop - {crop.crop.toUpperCase()}</p>
                                <p>Year - {crop.year}</p>
                                {userType && (userType != 'viewer') && (
                                    <div className="mt-2">
                                        <button className="btn btn-sm btn-primary" onClick={() => handleSaveRowEdits(crop._id)}> <i className="typcn typcn-edit"></i></button> &nbsp;
                                        <button className="btn btn-sm btn-danger" onClick={() => handleCancelRowEdits(crop._id)} disabled={disabledIds.includes(crop._id) === true}>
                                            {disabledIds.includes(crop._id) === false ? <> <i className="typcn typcn-delete btn-icon-append"></i></> : <>Deleting...</>}
                                        </button>
                                        <hr/>
                                    </div>
                                )}
                            </div>

                        )
                        : <span className="text-danger"><i>No Crops to show</i></span>
                    }
                </div>
            }
        },
        
        {
            accessorKey: 'block',
            header: 'Block',
            Cell : ({cell}) => {
                return <>{cell.getValue().toUpperCase()}</>
            }
        },
        {
            accessorKey : 'tehsil',
            header : 'Tehsil',
            Cell : (data) => {
                const {tehsil} =  data.row.original;
                return <>{tehsil!=undefined ? tehsil.toUpperCase() : <span className="text-danger">Not Entered</span> }</>
            }
        },
        {
            accessorKey: 'district',
            header: 'District',
            Cell : ({cell}) => {
                return <>{cell.getValue().toUpperCase()}</>
            }
        },
    ];

    useEffect(() => {
        (async () => {
            const url = new URL(`${url_}/manage-feild`);
            url.searchParams.set('page', pagination.pageIndex + 1);
            url.searchParams.set('limit', pagination.pageSize);
            if(crop!='')
                url.searchParams.set('cropName', crop);

            if(year!='')
                url.searchParams.set('year', year);

            console.log(columnFilters)
            for (let item of columnFilters) {
                url.searchParams.set(item.id, item.value)
            }

            if (globalFilter.length > 0) {
                url.searchParams.set('farmername', globalFilter)
            }

            setIsRefetching(true);
            let response = await getTableData(url.href);
            console.log(response);

            setIsRefetching(false);
            let data = response.data;
            let count = response.metadata[0]?.total || 0;
            
            setData(data);
            setRowCount(count);

            response = await getFieldStatus();
            const {totalRecords, cropsCount} = response;
            setSuccess(cropsCount);
            setPending(totalRecords-cropsCount);
        })();
    }, [columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting, flag, crop, year]);

    const csvUploadHandler = (event) => {
        setFileUploadingErrors([]);
        const files = event.target.files[0];
        if (files) {
            if (isFileCSV(files) === false) {
                return;
            }
            let formData = new FormData();
            formData.append('file', files);
            setDisabled(true);
            uploadCropsByCSV(formData).then((response) => {
                setFlag(!flag);
                setDisabled(false);
                if (response.result === 'success') {
                    NotificationManager.success("CSV File uploaded successfully.");
                    setFileUploadingErrors(response.errors);
                }
            }).catch((error) => {
                setDisabled(false);
                NotificationManager.error("Unable to Process CSV file. Please check the headers.");
            })

            event.target.value = "";
        }

    }
    const handleSaveRowEdits = (id) => {
        navigate(`/update-feild-information/${id}`);
    }
    const handleCancelRowEdits = (id) => {
        AlertConfirm({
            title: 'Are you sure?',
            desc: 'This cannot be undone.',
            onOk: () => {
                setDisabledIds(prev => [...prev, id]);
                deleteFeildData(id).then((response) => {
                    if (response.result === 'success') {
                        NotificationManager.success('Deleted the Record successfully.')
                    }
                    setFlag(!flag);
                })
            },
            onCancel: () => {
                // console.log('cancel');
            }
        });
    }

    const handleExportData = () => {
        const csv = generateCsv(csvConfig)(data);
        download(csvConfig)(csv);
    };
    useEffect(() => {
        (async() => {
            let response = await fetchOptions('pests-and-diseases');
            let crops = Object.keys(response.data[0].data);
            setCropList(crops)
        })();
        
    }, [])
    if (data === undefined) {
        return <Spinners1 />
    }
    return (
        <div>
            <p>{success} RECORDS <span className="text text-success">UPLOADED <i className="typcn typcn-input-checked"></i></span></p>
            <p>{pending} RECORDS <span className="text text-danger">PENDING <i className="typcn typcn-warning"></i> </span></p>
            <CSVErrorMessages errors={fileUploadingErrors} />

            
            <MaterialReactTable
                columns={columns}
                data={data}
                muiTableProps={{
                    sx: {
                        border: '1px solid rgba(81, 81, 81, 1)',
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        border: '1px solid rgba(81, 81, 81, 1)',
                    },
                }}
                muiTableBodyCellProps={{
                    sx: {
                        border: '1px solid rgba(81, 81, 81, 1)',
                    },
                }}
                // enableEditing={userType && (userType != 'viewer')}
                muiTablePaginationProps={{
                    showFirstButton: true,
                    showLastButton: true,
                }}
                renderTopToolbarCustomActions={() => (
                    <div>
                        {userType != 'viewer' ? (
                            <div style={{display : 'flex', justifyItems : 'space end'}}>
                                <button className='btn btn-sm btn-bluish-green  btn-icon-text'
                                    onClick={() => navigate('/add-feild-information')}
                                    variant="contained">
                                     <span>New </span>
                                </button> &nbsp;
                                
                            </div>
                        ) : null}
                        {/* <button
                            className='btn-sm btn-primary  btn-icon-text'
                            onClick={handleExportData}
                            ><i className='typcn typcn-download btn-icon-prepend'></i> <span>Export</span>  
                        </button>&nbsp; */}
                        {/* Custom bootstrap upload file */}
                        {/* <label htmlFor="fileUpload" className="file-upload btn-sm btn-primary" style={{cursor : disabled === true ? 'auto' : 'pointer'}}><i className='typcn typcn-cloud-storage btn-icon-prepend' style={{fontSize : '16px'}}></i> Upload CSV
                            <input id="fileUpload" style={{display : 'none'}} type="file" onInput={(event) => csvUploadHandler(event)} disabled={disabled}/>
                        </label> */}
                    </div>
                )}
                // enableExpandAll={false} //disable expand all button
                //conditionally render detail panel
                // renderDetailPanel={({ row }) => 
                //     row.original.crop.length > 0 ? 
                //         <div>
                //             <h5 className="mb-2">Crop Details</h5>
                //             {row.original.crop.map((crop, index) => <div className="mt-4" key={index}>
                //                 <h6 style={{ textDecoration: 'underline', fontStyle: 'italic' }}>Crop - {index + 1}</h6>
                //                 <CropDetails crop={crop} />
                //                 {userType && (userType != 'viewer') && (
                //                     <div>
                //                         <button className="btn btn-sm btn-primary" onClick={() => handleSaveRowEdits(crop._id)}>Edit <i className="typcn typcn-edit"></i></button> &nbsp;
                //                         <button className="btn btn-sm btn-danger" onClick={() => handleCancelRowEdits(crop._id)} disabled={disabledIds.includes(row.original._id) === true}>
                //                             {disabledIds.includes(row.original._id) === false ? <>Delete <i className="typcn typcn-delete btn-icon-append"></i></> : <>Deleting...</>}
                //                         </button>
                //                     </div>
                //                 )}
                //                 <hr />
                //             </div>)}
                //         </div> : <div style={{ fontStyle: 'italic' }}>No crop details to show</div>
                // }
                manualPagination={true}
                onPaginationChange={setPagination}
                manualFiltering={true}
                onColumnFiltersChange={setColumnFilters}
                state={{
                    pagination,
                    showProgressBars: isRefetching,
                }}
                rowCount={rowCount}
                onGlobalFilterChange={setGlobalFilter}
            />
            <NotificationContainer />
        </div>
    );
}

export default FeildInformation;


{/* <div className="row justify-content-start px-2">
                <select className='col-md-2 col-sm-5 form-control' value={year} onChange={(event) => setYear(event.target.value)}>
                    <option>Year</option>
                    {
                        yearsArray.map((item) => <option value={item}>{item}</option>)
                    }
                </select> &nbsp;
                <select className='col-md-2 col-sm-5 form-control' value={crop} onChange={(event) => setCrop(event.target.value)}>
                    <option>Crop</option>
                    {
                        cropList.map((crop, index) => <option  key={index} value={crop} style={{textTransform : 'capitalize'}}>{crop}</option>)
                    }
                </select> &nbsp;
            </div> */}