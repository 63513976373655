import {Document, Page, View, Text, Font, StyleSheet, Image } from '@react-pdf/renderer';
import src from './NotoSans-Regular.ttf';
import src1 from './CedarvilleCursive-Regular.ttf';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { hindiMap } from '../../../../../ini/values';
Font.register({
    family: 'Noto Sans Hindi',
    fonts: [
      {
        src : src
      }
    ],
  })
  
  Font.register({
    family : 'Cedarville Cursive',
    fonts : [
      {
        src : src1
      }
    ],
    fontStyle : 'normal',
    fontWeight : 500
  })

const styles = StyleSheet.create({
kalavidhi : {
    margin : '10px',
    padding: '20px',
    backgroundColor : 'rgb(221, 221, 221)'
},
view : { margin : '10px' },
document : { margin: 10, fontFamily : 'Noto Sans Hindi' },
page : { fontSize : '12px', padding : '15px'},
pageEng : { fontSize : '10px', padding : '15px'},
mainHeading : { fontSize : '19px', textDecoration : 'underline', textAlign : 'center'},
textHeading : { marginTop : '10px', fontSize : '14px'},
textHeadingEng : { marginTop : '10px', fontSize : '12px' },
signature : {fontFamily : 'Cedarville Cursive'},
dyanamicText : {textDecoration : 'underline',  textTransform : 'capitalize'}
});

const PDFContentU = (props) => {
    console.log(props.undertaking);
 if(props.undertaking === undefined){
    return <p>Loading...</p>
 }
 return (
   
        <Document style={styles.document}>
        {/* Hindi */}
        <Page size="A4" style={styles.page}>
            <View style={styles.view}>
                 <Text style={styles.mainHeading}>
                Undertaking Agreement
                </Text>
                <Text style={{ marginTop: '20px' }}>
                  यह प्रमाणित किया जाता है कि मैं,{' '}
                  <Text style={styles.dyanamicText}>{props.undertaking.ownerName}</Text>,{' '}
                  {hindiMap[props.undertaking.ownerRelation]} <Text style={styles.dyanamicText}>{props.undertaking.ownerGuardian}</Text>,{' '}
                  गाँव <Text style={styles.dyanamicText}>{props.undertaking.ownerVillage}</Text>,{' '}
                  ब्लॉक <Text style={styles.dyanamicText}>{props.undertaking.ownerBlock}</Text>,{' '}
                  जिला <Text style={styles.dyanamicText}>{props.undertaking.ownerDistrict}</Text>,{' '}
                  अनुमति {props.undertaking.ownerRelation === 'son' ? 'देता' : 'देती'} हूं कि <Text style={styles.dyanamicText}>{props.undertaking.farmerName}</Text>,{' '}
                  वर्ष <Text style={styles.dyanamicText}>{props.undertaking.ownerDate.split('-')[0]}</Text> में मेरे खेत पर, जिसकी पट्टा/खसरा संख्या{' '}
                  <Text style={styles.dyanamicText}>{props.undertaking.khasraNumbers.join(', ')}</Text> है और क्षेत्रफल (एकड़ में){' '}
                  <Text style={styles.dyanamicText}>{props.undertaking.area}</Text> है, निम्नलिखित उन्नत कृषि पद्धतियों को अपनाते हुए खेती कर सकता  है। 
                  वह {props.undertaking.ownerRelation === 'son' ? 'मेरा' : 'मेरी'} <Text style={styles.dyanamicText}>{hindiMap[props.undertaking.relation]}</Text> है। इस समझौते के साथ, यह सहमति हुई है कि इस नामांकित भूमि से उत्पन्न होने वाले 
                  सभी कार्बन क्रेडिट भारतरोहण के नाम पर पंजीकृत किए जाएंगे ताकि उन्हें तीसरे पक्ष के खरीदार को बेचा जा सके। 
                  कार्बन क्रेडिट के निर्माण के बाद से ही उन पर सभी अधिकार, शीर्षक और हित भारतरोहण के पास निहित हो जाएंगे।
                </Text>

                <Text style={{marginTop : '20px'}}></Text>
                {props.undertaking.ownerSign!="" ? <Image src={props.undertaking.ownerSign}  style={{height : 50, width : 50, textAlign : 'right'}} /> : <Text style={styles.signature}>{props.undertaking.ownerName}</Text>}
                <Text>भूस्वामी के हस्ताक्षर</Text>
                <Text>दिनांक : {moment(props.undertaking.ownerDate).format('DD-MM-YYYY')}</Text>
                
                <Text  style={{marginTop : '20px'}} >स्वीकृतकर्ता,</Text>
                {props.undertaking.farmerSign!="" ? <Image src={props.undertaking.farmerSign}  style={{height : 50, width : 50, textAlign : 'right'}} /> : <Text style={styles.signature}>{props.undertaking.farmerName}</Text>}
                <Text>खेती करने वाले किसान के हस्ताक्षर</Text>
                <Text>दिनांक : {moment(props.undertaking.farmerDate).format('DD-MM-YYYY')}</Text>
            </View>
        </Page>

        {/* English */}


        <Page size="A4" style={styles.page}>
            <View style={styles.view}>
             <Text style={styles.mainHeading}>Undertaking Agreement</Text>
          
                     
              <Text style={{ marginTop: '20px' }}>
                This is to certify that I,{' '}
                <Text style={styles.dyanamicText}>{props.undertaking.ownerName}</Text>,{' '}
                {props.undertaking.ownerRelation} of <Text style={styles.dyanamicText}>{props.undertaking.ownerGuardian}</Text>,{' '}
                Village <Text style={styles.dyanamicText}>{props.undertaking.ownerVillage}</Text>, Block{' '}
                <Text style={styles.dyanamicText}>{props.undertaking.ownerBlock}</Text>, District{' '}
                <Text style={styles.dyanamicText}>{props.undertaking.ownerDistrict}</Text>, is allowing{' '}
                <Text style={styles.dyanamicText}>{props.undertaking.farmerName}</Text> to do cultivation at my land with Patta/ Khasra No.{' '}
                <Text style={styles.dyanamicText}>{props.undertaking.khasraNumbers.join(', ')}</Text> with an area (in acre) of{' '}
                <Text style={styles.dyanamicText}>{props.undertaking.area}</Text> for following Improved agricultural practices in the year{' '}
                <Text style={styles.dyanamicText}>{props.undertaking.ownerDate.split('-')[0]}</Text>. He is my{' '}
                <Text style={styles.dyanamicText}>{props.undertaking.relation}</Text>. With this agreement, it has been agreed that all carbon credits generated from this enrolled land shall be registered in the name of BharatRohan to sell to the third-party buyer. BharatRohan shall have all the rights, title, and interest to such carbon credits upon generation.
              </Text>
                 
                <Text style={{marginTop : '20px'}}></Text>
                {props.undertaking.ownerSign!="" ? <Image src={props.undertaking.ownerSign}  style={{height : 50, width : 50, textAlign : 'right'}} /> : <Text style={styles.signature}>{props.undertaking.ownerName.toLowerCase()}</Text>}
                <Text>Signature of Landowner</Text>
                <Text>Date: {moment(props.undertaking.ownerDate).format('DD-MM-YYYY')}</Text>
               
                <Text style={{marginTop : '20px'}}>Accepted by,</Text>
                {props.undertaking.farmerSign!="" ? <Image src={props.undertaking.farmerSign}  style={{height : 50, width : 50, textAlign : 'right'}} /> : <Text style={styles.signature}>{props.undertaking.farmerName.toLowerCase()}</Text>}
                <Text>Signature of Cultivating Farmer</Text>
                <Text>Date: {moment(props.undertaking.farmerDate).format('DD-MM-YYYY')}</Text>
            </View>
        </Page>
    </Document>
  )
}

export default PDFContentU;
