import React, { useState } from 'react'
import { deleteLeasedAgreement, deleteUndertakingDocument } from '../../../../APIs/apiCalls';
import { NotificationManager } from 'react-notifications';
import AlertConfirm from 'react-alert-confirm';
import PDFContent from './LeaseAgreementDownload/PDFContent';
import PDFContentU from './Undertaking/PDFContentU';
import { PDFDownloadLink } from '@react-pdf/renderer';

const AgreementLists = ({undertaking, leasedAgreement, changeRecords, addU, addL}) => {
  

  const [disabled1, setDisabled1] = useState(false);
  const [disabled2, setDisabled2] = useState(false);
  console.log(undertaking)
  const deleteHandler1 = (id) => {
    AlertConfirm({
        title: 'Are you sure?',
        desc: 'This cannot be undone.',
        onOk: () => {
            setDisabled1(true)
            deleteUndertakingDocument(id).then(response => {
                setDisabled1(false)
                console.log(response);
                NotificationManager.success('Agreement Removed Successfully.');
                changeRecords();
            }).catch(error => {
                setDisabled1(false);
                NotificationManager.error('Internal Server Error');
            })
        },
        onCancel: () => {
          // console.log('cancel');
        }
    });
    
  }

  const deleteHandler2 = (id) => {
    AlertConfirm({
        title: 'Are you sure?',
        desc: 'This cannot be undone.',
        onOk: () => {
            setDisabled2(true)
            deleteLeasedAgreement(id).then(response => {
                setDisabled2(false)
                console.log(response);
                NotificationManager.success('Leased Agreement Removed Successfully.');
                changeRecords();
            }).catch(error => {
                setDisabled2(false);
                NotificationManager.error('Internal Server Error');
            })
        },
        onCancel: () => {
          // console.log('cancel');
        }
    });
    
  }
    return (
    <div>
        <h4>Undertaking And Leased Documents Records.</h4>
       
        {
            undertaking && undertaking.length>0 ? <div className='p-4' style={{background : 'rgb(247 243 234)'}}>
                <h6 style={{textDecoration : 'underline'}} >Undertaking Document</h6>
                <p>Status : <span className='badge bg-success text-white'>UPLOADED  <i className='typcn typcn-input-checked'></i> </span></p> 
                <div className='row'>
                {
                    undertaking.map((item, index) => 
                        <div className='col-md-6 mt-4' key={index}>
                            <p>{index+1}.  <b>Khasra Numbers</b> : {item.khasraNumbers?.join(', ')}, <b>Area</b> : {item.area} Acres</p> 
                            
                            <PDFDownloadLink
                                document={<PDFContentU undertaking={item} />}
                                fileName={`${item.farmerName}_${Date.now()}.pdf`}
                            >
                                {({ blob, url, loading, error }) =>
                                    error ? 'Error is there' :
                                    loading ? 'Loading...' : 
                                    <button className="btn btn-sm btn-primary">
                                        <i className="typcn typcn-download btn-icon-prepend"></i> Download
                                    </button>
                                }
                            </PDFDownloadLink> &nbsp;
                            <button className="btn btn-sm btn-danger" onClick={() => deleteHandler1(item._id)} disabled={disabled1}> <i className="typcn typcn-delete btn-icon-prepend"></i> Remove</button>
                            
                        </div>
                    )
                }
               {/* <hr className = 'mt-2'/> */}
               </div>
               <button className='btn btn-sm btn-info mt-2' disabled={disabled2} onClick={() => addU()}> + Add Undertaking Document </button>
            </div> : <><p className='text-danger mt-3'><i className='typcn typcn-warning'></i>  Undertaking Agreement is not uploaded.  <button className='btn btn-sm btn-info' disabled={disabled2} onClick={() => addU()}>  Sign Undertaking Agreement  </button></p></>
        }

        <hr/>
        {
             leasedAgreement.length>0 ? <div  className='p-4' style={{background : 'rgb(247 243 234)'}}> 
                <h6 style={{textDecoration : 'underline'}}>Leased Document</h6>
                <p>Status : <span className='badge bg-success text-white'>UPLOADED  <i className='typcn typcn-input-checked'></i> </span></p>

                <div className='row'>
                
                {
                    leasedAgreement.map((item, index) => <div className='col-md-6 mt-4'>
                        
                        <p>{index+1} . <b>Khasra Numbers</b> : {item.khasraNumbers.join(', ')}, <b>Area</b> : {item.area} Acres</p> 
                        
                        <PDFDownloadLink key={index} document={<PDFContent leasedAgreement = {item} />} fileName={`${item.farmerName}_${item.farmerVillage}_${item.farmerBlock}_${item.farmerDistrict}_${Date.now()}.pdf`}>
                                {
                                    ({ blob, url, loading, error }) => loading ? 'Loading document...' : <button className="btn btn-sm btn-primary"><i className="typcn typcn-download btn-icon-prepend"></i> Download </button> 
                                }
                        </PDFDownloadLink>
                        {/* &nbsp;
                        <button className='btn btn-sm btn-success' disabled={disabled2}><i className="typcn typcn-edit btn-icon-prepend"></i> Edit</button> */}
                        &nbsp;
                        <button className='btn btn-sm btn-danger' onClick={() => deleteHandler2(item._id)} disabled={disabled2}><i className="typcn typcn-delete btn-icon-prepend"></i> Remove</button>
                        &nbsp;
                        
                    </div>)
                }
                 {/* <hr/> */}
                 </div>
                <button className='btn btn-sm btn-info mt-2' disabled={disabled2} onClick={() => addL()}> + Add Lease Agreement </button>
                
            </div> : <p className='text-danger mt-3'><i className='typcn typcn-warning'></i>  Leased Agreement is not uploaded. <button className='btn btn-sm btn-info' disabled={disabled2} onClick={() => addL()}>  Sign Lease Agreement  </button></p>
        }
       
    </div>
  )
}

export default AgreementLists