import { downloadFile } from "../../Utils/commonFunctions";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFContent from "./Agreement/PDFContent";
import { projectList } from "../../ini/values";


const DocumentStatus = ({farmer, user}) => {
    return (
        <div className="mt-3 ">
                <h5>Document Status {farmer.projectName} </h5>

                <p>Passbook : { farmer.bank != undefined ? <>
                <span className="text-success">Uploaded </span>
                <a  href={farmer.bank.uri} target="_blank">View <i className="typcn typcn-export  btn-icon-prepend"></i></a> 
                <button  className="btn-xs btn-primary" onClick={() => downloadFile(farmer.bank.uri)}>Download  <i className="typcn typcn-download btn-icon-prepend"></i></button> 
                </> : <span className="text-danger">Pending</span>}</p>
                
                
                <p>Aadhar Card : { farmer.aadhar != undefined ? <>
                <span className="text-success">Uploaded </span> 
                
                <a  href={farmer.aadhar.frontImageUri} target="_blank">View  <i className="typcn typcn-export  btn-icon-prepend"></i></a> 
                <button className="btn-xs btn-primary" onClick={() => downloadFile(farmer.aadhar.frontImageUri)}>Download Front <i className="typcn typcn-download btn-icon-prepend"></i></button>
                <a  href={farmer.aadhar.backImageUri} target="_blank">View  <i className="typcn typcn-export  btn-icon-prepend"></i></a>
                
                <button className="btn-xs btn-primary" onClick={() => downloadFile(farmer.aadhar.backImageUri)}>Download Back <i className="typcn typcn-download btn-icon-prepend"></i></button>
                </> : <span className="text-danger">Pending</span>}</p>
                
                <p>Pan Card : { farmer.pan != undefined ? <>
                <span className="text-success">Uploaded </span> 
                <a  href={farmer.pan.uri} target="_blank">View <i className="typcn typcn-export  btn-icon-prepend"></i></a> 
                <button className="btn-xs btn-primary" onClick={() => downloadFile(farmer.pan.uri)}>Download  <i className="typcn typcn-download btn-icon-prepend"></i></button>
                </> : <span className="text-danger">Pending</span>}</p>
                
                
                {
                    farmer.projectName === projectList[0].value && 
                    <p>Farmer Agreement : { farmer.isAgreementSigned === "success" ? <>
                    <span className="text-success">Signed </span>
                    <PDFDownloadLink document={<PDFContent farmerDetails={farmer} signatureURI = {farmer.signature} />} fileName={`${farmer.farmername}_${farmer.village}`}>
                            {
                                ({ blob, url, loading, error }) => loading ? 'Loading document...' : <button className="btn-xs btn-primary"><i className="typcn typcn-download btn-icon-prepend"></i> Download </button>
                            }
                    </PDFDownloadLink>
                    </> : <span className="text-danger">Pending</span>}</p>
                }
                
                <hr/>
            </div>
    );
}

export default DocumentStatus;