import React from 'react'
import YearWiseFarmer from '../Components/YearWiseFarmer';
import AWDAreaPieChart from '../Components/AWDAreaPieChart';
import StatusRecords from '../Components/StatusRecords';

import StatesBarChart from '../Components/StatesBarChart';
import BarChart from '../Components/BarChart'

import StatesCoveredMap from '../Components/StatesCoveredMap'
import PieChart from '../Components/PieChart'

const CommonDashboard = ({data}) => {
  console.log('====================================');
  console.log(data);
  console.log('====================================');
  
  return (
    <div>
      <div>
          <StatusRecords data={data}/>
      </div>
      
      <div className="row m-0 justify-content-between">
        <YearWiseFarmer/>
        {
          data!=undefined && <AWDAreaPieChart data={data}/>
        }
      </div>

      <div className="row mt-3">
          {
              data!=undefined && <div className="col-md-4" style={{ padding : '10px'}}> <StatesBarChart data={data}/> </div>
          }
          {
              data!=undefined && <div className="col-md-8" style={{ padding : '10px'}}><BarChart data={data}/></div>
          }
      </div>

      <div className="row mt-3">
          {
              data!=undefined && <div className="col-md-6"> <StatesCoveredMap data={data}/> </div> 
          }

          {
              data!=undefined &&
              <div className="col-md-6"> <PieChart data={data}/></div> 
          }
      </div>
      
    </div>
  )
}

export default CommonDashboard;