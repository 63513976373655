import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { useEffect, useState } from "react";
import { Pie, Line } from "react-chartjs-2";
import PieChart from "./PieChart";
import AWDAreaPieChart from "./AWDAreaPieChart";
import ChartDataLabels from 'chartjs-plugin-datalabels';

export const Data = [
    {
      id: 1,
      year: 2016,
      userGain: 700,
      userLost: 823
    },
    {
      id: 2,
      year: 2017,
      userGain: 2000,
      userLost: 345
    },
    {
      id: 3,
      year: 2018,
      userGain: 4000,
      userLost: 823
    },
    {
      id: 4,
      year: 2019,
      userGain: 6000,
      userLost: 345
    },
    {
      id: 5,
      year: 2020,
      userGain: 8000,
      userLost: 555
    },
    {
      id: 6,
      year: 2021,
      userGain: 9000,
      userLost: 4555
    },
    {
      id: 7,
      year: 2022,
      userGain: 10000,
      userLost: 234
    },
    {
      id: 8,
      year: 2023,
      userGain: 19000,
      userLost: 234
    },
    {
      id: 9,
      year: 2024,
      userGain: 50700,
      userLost: 234
    }
  ];

const YearWiseFarmer = () => {
    const [chartData, setChartData] = useState({
        labels: Data.map((data) => data.year), 
        datasets: [
          {
            label: "Farmers Onboarded",
            data: Data.map((data) => data.userGain),
            backgroundColor: [
              '#769f34'
            ],
            borderColor: "black",
            borderWidth: 2,
          }
        ]
      });
      useEffect(() => {

      },[])
      return (
          <div className="col-md-8 p-0 pr-2">
            <div className='dashboard-heading'>
                  <h5 className="m-0 text-white">Year wise Farmers enrolled from 2016 to {(new Date()).getFullYear()}</h5>
            </div>
            <div className="dashboard-chart">
              <Line
                data={chartData}
                options={{
                  plugins: {
                    title : {
                      display : true,
                      align : 'left',
                      text : 'Line chart shows number of farmers onboarded every year'
                    },
                    datalabels: {
                      display: true,
                      color: "black",
                      offset : 1,
                      textAlign : 'right',
                      align : 'top',
                      textStrokeWidth  : 0.6,
                      font : {
                        size : 11
                      },
                      formatter : (value, context) => {return `${value} farmers`}
                    },
                    legend: {
                      display: true
                    }
                  }
                }}
                plugins={[ChartDataLabels]}
              />
            </div>
          </div>
      );
}


export default YearWiseFarmer;