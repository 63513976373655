import { useEffect, useState } from "react";
import 'chart.piecelabel.js';
import { getDashboardData, isTokenValid } from "../../APIs/apiCalls";
import { useNavigate } from "react-router-dom";
import AWDDashboard from "./AWDDashboard/AWDDashboard";
import { projectList, userRoles } from "../../ini/values";
import IPMDashboard from "./IPMDashboard/IPMDashboard";
import ThreeLoaders from "../../Components/ThreeLoaders/ThreeLoaders";
import CommonDashboard from "./CommonDashboard/CommonDashboard";
import Spinners from "../../Components/Spinners/Spinners";
const Dashboard = () => {

    const [user, setUser] = useState(undefined);
    const [projects, setProjects_] = useState(undefined);
    const [data, setData] = useState(undefined);
    const [project, setProjectSelected] = useState(undefined);
    
    useEffect(() => {
        (async() => {
                const token = localStorage.getItem('token');
                console.log(token);
                
                const response = await isTokenValid({token});
                console.log(response);
                
                setProjects_(response.data.projects);
                setUser(response.data);

                if(project === undefined){
                    console.log("project ==>" , project);
                    
                    setProjectSelected(response.data.projects[0]);
                }
                setData(undefined)
                const data = await getDashboardData({project : project === undefined ? response.data.projects[0] : project});
                console.log(data);
                setData(data);
        })();
    }, [project]);
    
    if(!user && !project){
        return <Spinners/>
    }
    
    return(
    <>
        <div className="col-md-12 grid-margin">
            <div className="card" >
                <div className='dashboard'>
                    <select className="form-control-sm mb-2" style={{padding : '.2375rem .75rem', outline : '1px solid lightgrey', color : 'grey', height : '1.9rem'}}
                    value={project}
                    onChange={(event) => setProjectSelected(event.target.value)}>
                        <option disabled>Select Dashboard to view from dropdown</option>
                        {
                            user.type === userRoles.superadmin && <option value={'all'}>All</option>
                        }
                        {
                            projects.map((project) => <option value={project}>{project}</option>)
                        }
                    </select>
                    

                        {
                            data != undefined && projects != undefined ? <>
                            {
                                project === projectList[0].value && <AWDDashboard data={data}/>
                            }

                            {
                                project === projectList[1].value && <IPMDashboard data={data}/>
                            }

                            {
                                project === 'all' && <CommonDashboard data={data}/>
                            }
                            </> : <p><ThreeLoaders/></p>
                        }
                </div>
            </div>
        </div>
    </>    
    );
};

export default Dashboard;