import React from 'react'
import { handleKMLDownload } from '../../Utils/commonFunctions';

const PolygonMapping = ({data, farmer}) => {
  
	
	
  return (
	<div className="card" style={{fontStyle : 'italic', textTransform : 'capitalize'}}>
		<div className="row p-3">
			<div className="col-md-12">
				<div className={`mb-2`}>
					<h5 className="text-info">Polygon Mapping</h5>
				</div>
				
				<div className='p-3' style={{backgroundColor : 'rgb(247, 243, 234)'}}>
					{
						data.length === 0 && <p className='text-danger'><i className='typcn typcn-warning'></i> Polygon maps are not uploaded.</p>
					}
					{
						data.map((polygon, index) =>(
							<div>
								<h6 className='mb-1'><b>#Polygon-{index+1}</b></h6>
								<p>Area Covered : {(polygon.area * 0.00024711).toFixed(2)} Acres</p>
								<a href={`/#/view/${polygon._id}/${index}`} target='_blank' className='btn btn-sm btn-success'>View Polygon File <i class="typcn typcn-export"></i></a>
							</div>
						))
					}
				</div>
			</div>
		</div>
	</div>
  )
}

export default PolygonMapping