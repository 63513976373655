export const religions = [
	'Hinduism',
	'Islam',
	'Christianity',
	'Sikhism',
	'Others'
]


export const projectList = [
    {
      label : 'Alternate Wetting and Drying (AWD)',
      value : 'Alternate Wetting and Drying (AWD)'
    },
    {
      label : 'IPM - Rajasthan Project',
      value : 'IPM - Rajasthan Project'
    }
  ];
  

 export const userRoles = {
	admin : 'admin',
	editor : 'editor',
	viewer : 'viewer',
	intern : 'intern',
	superadmin : 'super-admin'
}

export const hindiMap = {
    son: 'पुत्र',
    daughter: 'पुत्री',
    wife: 'पत्नी',
    husband: 'पति',
    mother: 'माँ / माता',
    father: 'पिता',
    brother: 'भाई',
    sister: 'बहन',
    grandson: 'पोता / नाती',
    granddaughter: 'पोती / नातिन',
    daughter_in_law: 'बहू',
    grandfather: 'दादा / नाना',
    grandmother: 'दादी / नानी',
    uncle: 'चाचा / मामा',
    aunt: 'चाची / मामी',
    nephew: 'भतीजा',
    niece: 'भतीजी'
};

export const relations = [
    {
        label: 'Son',
        value: 'son'
    },
    
    {
        label: 'Daughter',
        value: 'daughter'
    },
    {
        label: 'Wife',
        value: 'wife'
    },
    {
        label: 'Husband',
        value: 'husband'
    },
    {
        label: 'Mother',
        value: 'mother'
    },
    {
        label: 'Father',
        value: 'father'
    },
    {
        label: 'Brother',
        value: 'brother'
    },
    {
        label: 'Sister',
        value: 'sister'
    },
    {
        label: 'Grandson',
        value: 'grandson'
    },
    {
        label: 'Granddaughter',
        value: 'granddaughter'
    },
    {
        label: 'Daughter-in-law',
        value: 'daughter_in_law'
    },
    {
        label: 'Grandfather',
        value: 'grandfather'
    },
    {
        label: 'Grandmother',
        value: 'grandmother'
    },
    {
        label: 'Uncle',
        value: 'uncle'
    },
    {
        label: 'Aunt',
        value: 'aunt'
    },
    {
        label: 'Nephew',
        value: 'nephew'
    },
    {
        label: 'Niece',
        value: 'niece'
    },
];
