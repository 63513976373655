import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'

const ThreeLoaders = () => {
  return (
	<>Loading <ClipLoader
		loading={true}
		size={14}
		aria-label="Loading Spinner"
		data-testid="loader"
	/> <ClipLoader
		loading={true}
		size={14}
		aria-label="Loading Spinner"
		data-testid="loader"
	/> <ClipLoader
		loading={true}
		size={14}
		aria-label="Loading Spinner"
		data-testid="loader"
	/></>
  )
}

export default ThreeLoaders