import React from 'react'
import StatusRecords from '../Components/StatusRecords'
import { projectList } from '../../../ini/values'
import YearWiseFarmer from '../Components/YearWiseFarmer'
import AWDAreaPieChart from '../Components/AWDAreaPieChart'
import StatesBarChart from '../Components/StatesBarChart'
import BarChart from '../Components/BarChart'
import { AWDStateBarChart } from '../Components/AWDStateBarChart'

const IPMDashboard = ({data}) => {
  return (
	<div>
		<StatusRecords data={data} project={projectList[1].value}/>
		<div className="row m-0 justify-content-between">
			<YearWiseFarmer/>
			{
				data!=undefined && <div className="col-md-4" style={{ padding : '10px'}}> <StatesBarChart data={data}/> </div>
			}
		</div>

		<div className="row">
			<AWDStateBarChart data={data}/>
		</div>
		<div className="row mt-3">
			{
				data!=undefined && <div className="col-md-12" style={{ padding : '10px'}}><BarChart data={data}/></div>
			}
		</div>

	</div>
  )
}

export default IPMDashboard