import { projectList } from '../../../ini/values';

const StatusRecords = ({data, project=projectList[0].value}) => {
    return (
            <div className="dashboard-cards mt-1">
                <div className="dashboard-card card-yellow grid-margin stretch-card">
                    <div className="d-icons card-icon card-yellow-dark">
                        <span className="vertical-center"><i className="typcn typcn-chart-line"></i></span>
                    </div>
                    <div className="pl-3 card-text">
                        <p>FARMERS ENROLLED </p>
                        <p>{data.projectFarmers}</p>
                    </div>
                    
                </div>
                <div className="dashboard-card card-d-green text-white grid-margin stretch-card">
                    <div className="d-icons card-icon card-d-green-dark">
                        <span className="vertical-center"><i  className="typcn typcn-chart-area"></i></span>
                    </div>
                    <div className="pl-3 card-text">
                        <p>AREA COVERED </p>
                        <p>{(data.totalArea * 0.00024711).toFixed(2)} Acres</p>                                                                
                    </div>
                </div>
                {
                    project === projectList[1].value &&
                    <div className="dashboard-card card-d-green text-white grid-margin stretch-card">
                        <div className="d-icons card-icon card-d-green-dark">
                            <span className="vertical-center"><i  className="typcn typcn-chart-area"></i></span>
                        </div>
                        <div className="pl-3 card-text">
                            <p>AREA COVERED </p>
                            <p>{(data.totalArea * 0.00024711).toFixed(2)} ACRES</p>                                                                
                        </div>
                    </div>
                }
                {
                    project != projectList[1].value &&
                    <div className="dashboard-card card-green text-white grid-margin stretch-card">
                        <div className="d-icons card-icon card-green-dark">
                            <span className="vertical-center"><i  className="typcn typcn-pipette"></i></span>
                        </div>
                        <div className="pl-3 card-text">
                            <p>PIPES INSTALLED </p>
                            <p>{data.aeration[0]?.count || 'NA'}</p>
                            {/* <p>500 Pipes</p>   */}
                        </div>
                    </div>
                }

                <div className="dashboard-card card-orange text-white grid-margin stretch-card">
                    <div className="d-icons card-icon card-orange-dark">
                        <span className="vertical-center"><i className="typcn typcn-map"></i></span>
                    </div>
                    <div className="pl-3 card-text">
                        <p> POLYGONS DRAWN</p>
                        <p>{ data.polygonNumber }</p>
                    </div>
                </div>
            </div>
    );

}

export default StatusRecords;

 {/* {
                isAWDFarmer === false ? <YearWiseFarmer farmers={farmers}/> : null
            } */}