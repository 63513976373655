import axios from 'axios';
import { fetchUrl } from './server';
const url = fetchUrl;

export function getHeaders(){
    const token = localStorage.getItem('token');
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };
    return headers;
}

export function  getFormHeaders(){
    const token = localStorage.getItem('token');
    const headers =  { 'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data'};
    return headers;
}

export async function userLogin (data){
    let response = await axios.post(`${url}/signin`, data);
    return response?.data;
}

export async function isTokenValid(data){
    let response = await axios.post(`${url}/verify-token`, data);
    return response?.data;
}


export async function userLogout(){
    let response = await axios.get(`${url}/logout`);
    return response?.data;
}

export async function addFarmer(entry){
    const headers = getHeaders();
    let response = await axios.post(`${url}/farmers`, entry, { headers });
    return response?.data;
}

export async function getfarmerById(id){
    
    const headers = getHeaders();
    let response = await axios.get(`${url}/farmers/${id}`, {headers});
    return response?.data;
}

export async function getfarmerProfile(id){
    const headers = getHeaders();
    let response = await axios.get(`${url}/farmer-profile/${id}`, {headers});
    return response?.data;
}

export async function deleteFarmer(id){
    const headers = getHeaders();
    let response = await axios.delete(`${url}/farmers/${id}`, {headers});
    return response?.data;
}

export async function updateFarmer(id, data){
    const headers = getHeaders();
    let response = await axios.put(`${url}/farmers/${id}`, data, { headers });
    return response?.data;
}

export async function fileUploadBackend(formData) {
  
    const headers = getHeaders();
    let response = await axios.post(`${url}/upload` , formData, {headers});
    return response?.data;
}


export async function addFeildData(formData){
    const headers = getHeaders();
    let response = await axios.post(`${url}/feilds` , formData, {headers});
    return response?.data;
}

export async function getFeildData(){
    const headers = getHeaders();
    let response = await axios.get(`${url}/feilds`, {headers});
    return response?.data;
}

export async function getFeildDataByFarmerId(farmerId){
    const headers = getHeaders();
    let response = await axios.get(`${url}/feilds-info/${farmerId}`, {headers});
    return response?.data;
}

export async function getFeildDataById(id){
    const headers = getHeaders();
    let response = await axios.get(`${url}/feilds/${id}`, {headers});
    return response?.data;
}

export async function updateFeildData(id, data){
    const headers = getHeaders();
    let response = await axios.put(`${url}/feilds/${id}`, data, {headers});
    return response?.data;
}

export async function deleteFeildData(id){
    const headers = getHeaders();
    let response = await axios.delete(`${url}/feilds/${id}`, {headers});
    return response?.data;
}

export async function getFieldStatus(){
    const headers = getHeaders();
    let response = await axios.get(`${url}/feild-status`, {headers});
    return response?.data;
}

export async function addAerationDetails(data){
    const headers = getHeaders();
    let response = await axios.post(`${url}/aeration`,data, {headers});
    return response?.data;
}

export async function getAerationDetailsByFarmerId(farmerId){
    const headers = getHeaders();
    let response = await axios.get(`${url}/aeration-by-farmer/${farmerId}`, {headers});
    return response?.data;
}

export async function updateAerationDetails(id, data){
    const headers = getHeaders();
    let response = await axios.put(`${url}/aeration/${id}`, data, {headers});
    return response?.data;
}

//delete aeration details by pipe number
export async function deleteAerationDetailByPipe(id, data){
    const headers = getHeaders();
    let response = await axios.put(`${url}/delete-aeration/${id}`, data, {headers});
    return response?.data;
}

//this is for when form headers passed.
export async function updateAerationDetails1(id, data){
    const headers = getFormHeaders();
    let response = await axios.put(`${url}/aeration-update/${id}`, data, {headers});
    return response?.data;
}

export async function deleteAerationDetails(id, farmerId){
    const headers = getHeaders();
    let response = await axios.delete(`${url}/aeration/${id}/${farmerId}`, {headers});
    return response?.data;
}

export async function getAerationCounts(){
    const headers = getHeaders();
    let response = await axios.get(`${url}/aeration-status`, {headers});
    return response?.data;
}

export async function addFeildTeam(data){
    const headers = getHeaders();
    let response = await axios.post(`${url}/user`, data, {headers});
    return response?.data;
}

export async function getFeildTeamRecords(){
    const headers = getHeaders();
    let response = await axios.get(`${url}/user`, {headers});
    return response?.data;
}



export async function getFeildTeamById(id){
    const headers = getHeaders();
    let response = await axios.get(`${url}/user/${id}`, {headers});
    return response?.data;
}

export async function updateFeildTeamRecord(id, data){
    const headers = getHeaders();
    let response = await axios.put(`${url}/user/${id}`, data, {headers});
    return response?.data;
}

export async function deleteFeildTeamById(id){
    const headers = getHeaders();
    let response = await axios.delete(`${url}/user/${id}`, {headers});
    return response?.data;
}


export async function getAdmin() {
    let response = await axios.get(`${url}/admin-profile`)
    return response
}
function isDataInCache() {
    return !!localStorage.getItem('locations');
}
  
export async function getLocations(){
    if(isDataInCache()){
        const response = JSON.parse(localStorage.getItem('locations'));
        return response;
    }else{
        let response = await axios.get(`${url}/location`);
        if(response?.data){
            localStorage.setItem('locations', JSON.stringify(response?.data))
        }
        return response?.data;
    }
}

//land records
export async function addLandRecords(data){
    let headers = getHeaders();
    let response = await axios.post(`${url}/land`, data, {headers});
    return response?.data;
}

//fetches land records specific to farmer
export async function getLandRecords(id){
    const headers = getHeaders();
    let response = await axios.get(`${url}/land/${id}`, {headers});
    return response?.data;
}

//fetches land records specific to farmer
export async function updateLandRecords(id, data){
    const headers = getHeaders();
    let response = await axios.put(`${url}/land/${id}`, data, {headers});
    return response?.data;
}

export async function deleteLandRecords(id, farmerId){
    const headers = getHeaders();
    let response = await axios.delete(`${url}/land/${id}/${farmerId}`, {headers});
    return response?.data;
}


//polygon mapp.

export async function addPolygon(data){
    const headers = getHeaders();
    let response = await axios.post(`${url}/polygon`, data, {headers});
    return response?.data;
}

export async function getPolygonById(id){
    const headers = getHeaders();
    let response = await axios.get(`${url}/polygon/${id}`, {headers});
    return response?.data;
}

export async function deletePolygonById(id){
    const headers = getHeaders();
    let response = await axios.delete(`${url}/polygon/${id}`, {headers});
    return response?.data;
}


//logs
export async function getLogRecords(){
    const headers = getHeaders();
    let response = await axios.get(`${url}/logs`, {headers});
    return response?.data;
}


export async function fetchOptions(collectionName){
    const headers = getHeaders();
    let response = await axios.get(`${url}/collection/${collectionName}`, {headers});
    return response?.data;
}

export async function updateOptions(id, data, collectionName){
    const headers = getHeaders();
    let response = await axios.put(`${url}/collection/${collectionName}/${id}`, data, {headers});
    return response?.data;
}

export async function deleteOption(id, collectionName){
    const headers = getHeaders();
    let response = await axios.delete(`${url}/collection/${collectionName}/${id}`, {headers});
    return response?.data;
}


export async function getPendingRecords(){
    const headers = getHeaders();
    let response = await axios.get(`${url}/pending` , {headers});
    return response?.data;
}

export async function updatePendingRecords(id, data, collection){
    const headers = getHeaders();
    let response = await axios.put(`${url}/pending/${collection}/${id}`, data , {headers});
    return response?.data;
}

export async function deletePendingRecords(id){
    const headers = getHeaders();
    let response = await axios.delete(`${url}/pending/${id}`, {headers});
    return response?.data;
}

export async function getPestsAndDiseases()
{
    const headers = getHeaders();
    let response = await axios.get(`${url}/pests-and-diseases`, {headers});
    return response?.data;
}

export async function updatePestsAndDiseases(id, data){
    const headers = getHeaders();
    let response = await axios.put(`${url}/pests-and-diseases/${id}`, data, {headers});
    return response?.data;
}

export async function addDocument(entry){
    const headers = getFormHeaders();
    let response = await axios.post(`${url}/document`, entry, {headers});
    return response?.data;
}

export async function fetchDocuments(){
    const headers = getHeaders();
    let response = await axios.get(`${url}/document`, {headers});
    return response?.data;
}

export async function fetchDocumentsByQuery(farmerId){
   
    const headers = getHeaders();
    let response = await axios.get(`${url}/documents/${farmerId}`, {headers});
    return response?.data;
}

export async function updateDocument(id, updatedEntry){
    const headers = getFormHeaders();
    let response = await axios.put(`${url}/document/${id}`, updatedEntry, {headers});
    return response?.data;
}

export async function deleteDocument(id){
    const headers = getHeaders();
    let response = await axios.delete(`${url}/document/${id}`, {headers});
    return response?.data;
}

// Location

export async function getLocation(){
    const headers = getHeaders();
    let response = await axios.get(`${url}/location`, {headers});
    return response?.data;
}


export async function addSingleLocation(data){
    const headers = getHeaders();
    let response = await axios.post(`${url}/add-location`, data, {headers});
    return response?.data;
}

export async function updateLocationRecord(id, data){
    const headers = getHeaders();
    let response = await axios.put(`${url}/location/${id}`, data, {headers});
    return response?.data;
}

export async function getLocationById(id){
    const headers = getHeaders();
    let response = await axios.get(`${url}/location/${id}`, {headers});
    return response?.data;
}

export async function deleteLocationById(id){
    const headers = getHeaders();
    let response = await axios.delete(`${url}/location/${id}`, {headers});
    return response?.data;
}

export async function uploadFarmerByCSV(formData){
    const headers = getHeaders();
    let response = await axios.post(`${url}/farmer-csv`, formData, {headers : getFormHeaders()});
    return response?.data;
}

export async function uploadDocumentsByCSV(formData){
    const headers = getHeaders();
    let response = await axios.post(`${url}/documents-csv`, formData, {headers : getFormHeaders()});
    return response?.data;
}

export async function uploadCropsByCSV(formData){
    const headers = getHeaders();
    let response = await axios.post(`${url}/feilds-csv`, formData, {headers : getFormHeaders()});
    return response?.data;
}
export async function uploadPolygonByCSV(formData){
    const headers = getHeaders();
    let response = await axios.post(`${url}/polygon-csv`, formData, {headers : getFormHeaders()});
    return response?.data;
}

export async function downloadFileByUrl(data){
    let response = await axios.post(`${url}/get-signed-url`, data);
    return response?.data;
}

export async function downloadFileInZip(data){
    let response = await axios.post(`${url}/download-zip`, data,  {
        responseType: 'blob'  // Important for handling binary data
    });
    return response?.data;
}

export async function addLeasedAgreement(formData){
    let response = await axios.post(`${url}/leased-agreement`, formData, {headers : getFormHeaders()});
    return response?.data;
}

export async function deleteLeasedAgreement(id){
    const headers = getHeaders();
    let response = await axios.delete(`${url}/leased-agreement/${id}`, {headers});
    return response?.data;
}

 export async function addUndertakingDocument(data){
    const headers = getHeaders();
    let response = await axios.post(`${url}/undertaking`, data, {headers});
    return response?.data;
 }

 export async function deleteUndertakingDocument(id){
    const headers = getHeaders();
    let response = await axios.delete(`${url}/undertaking/${id}`, {headers});
    return response?.data;
 }

 export async function getDashboardData(data){
    const headers = getHeaders();
    let response = await axios.post(`${url}/dashboard-data`,data, {headers});
    return response?.data;
 }


 export async function getTableData(url){
    const headers = getHeaders();
    let response = await axios.get(url, {headers});
    return response?.data;
 }

 export async function exportCSV(url){
    const headers = getHeaders();
    const response = await fetch(url, {headers});
    const blob = await response.blob();
    return blob;
}

export async function uploadFileToSpace(formData){
    const headers = getFormHeaders();
    let response = await axios.post(`${url}/upload-file`,formData,  {headers}); 
    return response?.data;
}

export async function deleteFileFromSpace(data){
    console.log(data);
    
    const headers = getHeaders();
    let response = await axios.post(`${url}/delete-file`,data,  {headers}); 
    return response?.data;
}