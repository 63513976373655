import { useEffect, useState } from "react";
import Filter from "./Filter";
import Layout from "../../Layout";
import { isTokenValid } from "../../APIs/apiCalls";

const Documents = () => {
    const [user, setUser] = useState({});
    useEffect(() => {
        (async ()=>{
            const token = localStorage.getItem('token');
            const response = await isTokenValid({token});
            setUser(response.data);
        })();
    },[])
    return (
        <div className="col-12 grid-margin">
            <div className="card">
                <div className="card-body">
                    <Filter user={user} />
                </div>
            </div>
        </div> 
    );
}

export default Documents;